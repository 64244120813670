import {createSlice} from '@reduxjs/toolkit';
import {flutterUser} from "./util";

const session = JSON.parse(localStorage.getItem("session") || "null")

const initialState = {
    session: session || null,
};

export const counterSlice = createSlice({
    name: 'global',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setSession: (state, action) => {
            localStorage.setItem("session", JSON.stringify(action.payload))
            localStorage.setItem("authorization", flutterUser.authorization)
            return {...state, session: action.payload}
        },
    },
});

//解构赋值语法，将 counterSlice.actions 对象中的属性分别赋值给了相应的常量
export const {setSession} = counterSlice.actions;

//选择器函数，收 state 参数并从 state.global 对象中获取相应的属性值
export const selectSession = (state) => state.global.session;

export default counterSlice.reducer;