import './index.css';
import Navigate from "../../components/navigate";

export default () => {

    return (
        <div className='rule'>
            <Navigate title='积分规则' dark border/>
            <div className='rule-body'>
                一、中济经络穴位积分
                <br/>
                中济经络穴位积分(以下简称“积分”)为中济经络穴位回馈用户的权益奖励，积分不具有货币或现金价值，不可兑现，不可转让，用户可以通过中济经络穴位使用行为和积分奖励活动等方式来获取积分，积分可以在积分中心兑换各类权益、参与各种积分活动，具体以积分中心及活动页面提示为准。
                <br/>
                二、积分领取规则
                <br/>
                积分任务完成后系统自动发放积分，积分发放后，用户可前往"我的"“积分中心"查看积分记录到账情况。
                <br/>
                三、积分获取方式
                <br/>
                1、用户可以通过使用中济经络穴位积分中心公示的方式获得积分，具体以"我的-积分中心-规则"页面提示为准。
                <br/>
                2、用户每日获取积分数量有相应限额，具体积分限额以产品页面提示为准。
                <br/>
                3、如遇系统繁忙等情况，积分将延迟发放。
                <br/>
                详细如下：
                <br/>
                （1）每日签到
                <br/>
                完成条件：用户每日首次进入积分中心自动完成签到。
                <br/>
                累积说明：签到后可获得2个积分，每日上限2分；
                <br/>
                任务重置：北京时间0：00重置；
                <br/>
                （2）浏览女性模型
                <br/>
                完成条件：连续浏览女性模型20秒；
                <br/>
                累积说明：完成后可获得1个积分，每日上限1分，最多完成5次；
                <br/>
                任务重置：北京时间0：00重置；
                <br/>
                （3）查百科
                <br/>
                完成条件：连续浏览百科详情页20秒
                <br/>
                累积说明：完成后可获得1个积分，每日上限1分；
                <br/>
                任务重置：北京时间0：00重置；
                <br/>
                （4）AI望诊
                <br/>
                完成条件：用户通过AI望诊完成一次舌诊或手诊或面诊行为；
                <br/>
                累积说明：完成后可获得2个积分，每日上限2分；
                <br/>
                任务重置：北京时间0：00重置；
                <br/>
                （5）看视频课程
                <br/>
                完成条件：累计观看APP视频课程20分钟；
                <br/>
                累积说明：完成后可获得5个积分，每日上限5分；
                <br/>
                任务重置：北京时间0：00重置；
                <br/>
                （6）分享朋友圈
                <br/>
                完成条件：分享APP内任意页面至朋友圈；
                <br/>
                累积说明：完成后可获得2个积分，每日上限2分；
                <br/>
                任务重置：北京时间0：00重置；
                <br/>
                （7）阅读
                <br/>
                完成条件：累计观看APP电子书10分钟；
                <br/>
                累积说明：完成后可获得2个积分，每日上限2分；
                <br/>
                任务重置：北京时间0：00重置；
                <br/>
                （8）2d经络模型
                <br/>
                完成条件：浏览2D经络模型30秒；
                <br/>
                累积说明：完成后可获得2个积分，每日上限2分，最多完成3次；
                <br/>
                任务重置：北京时间0：00重置；
                <br/>
                （9）社区回答
                <br/>
                完成条件：进行社区回答1次；
                <br/>
                累积说明：完成后可获得2个积分，每日上限2分，最多完成1次；
                <br/>
                任务重置：不重置；
                <br/>
                （10）社区评论
                <br/>
                完成条件：在社区评论1次；
                <br/>
                累积说明：完成后可获得1个积分，每日上限1分，最多完成1次；
                <br/>
                任务重置：不重置；
                <br/>
                四、积分权益兑换
                <br/>
                1、兑换参与条件
                <br/>
                (1)用户参与权益兑换需为中济经络穴位注册用户。
                <br/>
                (2)同一中济经络穴位账户、同一手机号码、同一终端设备，满足以上任一条件均视为同一用户。
                <br/>
                (3)中济经络穴位客户端版本需在2.11.0及以上的版本才能参与积分权益兑换。
                <br/>
                2、兑换规则
                <br/>
                (1)用户可在“我的"积分中心"相应页面兑换权益，权益随机展示，兑换要求、使用规则等详情具体以页面展示为准。
                <br/>
                (2）请用户理解，由于受限于库存数量，我们的权益兑完即止，先兑先得。
                <br/>
                3、权益说明
                <br/>
                权益为全积分权益的，用户仅需消特相应积分即可获得相应权益，权益为积分加钱购权益的，用户兑换后可获得相应权益的优惠券，用户需在优惠券使用界面支付相应金额后方能获得对应商品。
                <br/>
                五、其他说明
                <br/>
                1、如用户存在违规刷积分行为(包括但不限于虚假交易、炒信、恶意退款、拆单、套现)，我们有权取消用户获得积分的资格，已发放的积分将被扣回。
                <br/>
                2、为了进一步改善用户体验，我们将不时更新积分服务的内容，积分规则也可能会随之更新，我们会以公告、客户端通知、短信或者弹窗等方式通知（统称"通知"）就更新内容向您告知，更新内容将在前述通知指定日期开始生效，若您不同意更新后的内容，您有权停止相关服务；双方协商一致的，也可另行变更相关服务对应内容。您也可以随时在本页面查阅积分规则的最新版本。
                <br/>
                3、为了保障更多用户的权益，中济经络穴位会采取必要的风险防控措施保障活动的安全稳定运营。
                <br/>
                4、用户曾经存在、出现或经平台合理怀疑存在违背诚实信用原则行为，用户将可能面临无法使用/获取优惠权益、仅可获取/使用部分优惠权益的地方，必要时追究用户的法律责任，包括但不限于以下行为：
                <br/>
                （1）通过任何不正当手段或以违反诚实守信原则的方式参与活动的，如通过与其他用户或利用机器等方式作弊、刷取积分等扰乱活动秩序的行为；
                <br/>
                （2）通过任何下正当手段或以违反诚实信用原则的方式达成交易的，如虚假下单交易、提供虚假交易信息扰乱活动秩序、违反交易规则的行为。
                <br/>
                5、为保证积分活动的有序进行，如本积分活动的参与方涉嫌存在违法违规行为（例如：洗钱、作弊、套现、侵犯他人合法权益、发布违法违规内容等）或存在买卖活动相关权益行为，中济经络穴位有权取消参与方的活动参与资格，删除违法违规内容、收回参与方从平台获取的权益（如有）等处置措施。
                <br/>
                6、本积分活的开展期间，此出现不可抗力等情况，例如发生自然灾害事件、遭受网络攻击或电信故障、停机维护、活动受法律法规、监管机构要求或政策指令需要停办或调整，平台单方暂停、中止或终止提供服务的，可免于承担责任。
                <br/>
                六、规则有效期
                <br/>
                本积分规则有效期自发布之日起生效，重庆知源健康管理有限公司有权根据需要不时制定、修改本协议及相关规则，变更后的协议将及时通知，通知后即取原协议及规则生效；如您不同意相关变更，应立即停止使用中济经络穴位积分服务，如您继续使用中济经络穴位积分服务，即表示您已经接受经修订的协议和规则。在法律允许范围内，积分规则最终解释权归重庆知源健康管理有限公司所有。
                <br/>
            </div>
        </div>
    )
}