import './index.css';
import {useEffect, useState} from "react";
import Navigate from "../../components/navigate";
import {flutterUser} from "../../util";
import JsonRequest from "../../request/json_request";
import dayjs from "dayjs";
import InfiniteScroll from "react-infinite-scroll-component";
import CustomLoading from "../../components/custom-loading";

export default () => {
    const [list, setList] = useState([]);
    const pageSize = 12;
    const [hasMore, setHasMore] = useState(true);

    useEffect(() => {
        if (!!!flutterUser.authorization) return;
        getRecords();
    }, []);

    const getRecords = () => {
        const param = {size: pageSize};
        if (list.length > 0) param.before = list[list.length - 1].occur_at;
        JsonRequest.headerClient(flutterUser).get('/api/coin/account/flow', param)
            .then(resp => resp.json())
            .then(({data}) => {
                if (data.length > 0)
                    setList([...list, ...data]);
                if (data.length === 0 || data.length < pageSize)
                    setHasMore(false);
            })
    }

    const RecordItem = ({data}) => {
        const {summary, amount, occur_at} = data;
        return (
            <div className='record-item'>
                <div className='record-missson'>
                    <span className='record-mission-name'>{summary}</span>
                    <span
                        className={`record-mission-award${amount < 0 ? ' award-minus' : ''}`}>{amount > 0 ? `+${amount}` : amount}</span>
                </div>
                <div className='record-sub-info'>
                    <span>{dayjs(occur_at * 1000).format('YYYY-MM-DD HH:mm')}</span>
                </div>
            </div>
        )
    }

    return (
        <div className='record'>
            <Navigate title='积分记录' dark border/>
            <div id='records' className='records'>
                <InfiniteScroll dataLength={list.length}
                                next={() => getRecords()}
                                hasMore={hasMore}
                                loader={<div style={{marginTop: '50px'}}><CustomLoading/></div>}
                                scrollableTarget='records'
                                endMessage={<div className='no-more-record'>没有更多了...</div>}>
                    {list.map(e => <RecordItem key={e.occur_at + e.summary} data={e}/>)}
                </InfiniteScroll>
            </div>
        </div>
    )
}