import {BrowserRouter, Routes, Route} from "react-router-dom"
import Integral from "./pages/integral";
import Goods from "./pages/goods";
import Record from "./pages/record";
import Rule from "./pages/rule";
import {message} from "antd";
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectSession, setSession} from "./global";
import JsonRequest from "./request/json_request";
import {flutterUser} from "./util";
import './index.css';
import CustomLoading from "./components/custom-loading";

function App() {
    const session = useSelector(selectSession)
    const dispatch = useDispatch()

    useEffect(() => {
        message.config({top: 100})
    }, [])

    if (!session || localStorage.getItem("authorization") !== flutterUser.authorization) {
        JsonRequest.headerClient(flutterUser).get('/api/distributed/mall/session')
            .then((resp) => resp.json())
            .then(({data}) => dispatch(setSession(data)))
        return <div className='page-loading'>
            <CustomLoading/>
        </div>
    } else {
        return <>
            <BrowserRouter>
                <Routes>
                    <Route path="/" exact element={<Integral/>}/>
                    <Route path="/goods" exact element={<Goods/>}/>
                    <Route path="/record" exact element={<Record/>}/>
                    <Route path="/rule" exact element={<Rule/>}/>
                </Routes>
            </BrowserRouter>
        </>;
    }
}

export default App;
