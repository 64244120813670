import  './index.css'
import LoadingGif from "../../images/loading.gif";

export default () => {

    return (
        <div className='custom-loading'>
            <img src={LoadingGif} width={50} height={50} alt=""/>
            <span>拼命加载中...</span>
        </div>
    )
}