import './index.css';
import {useLocation} from "react-router-dom";
import {sendFlutterMessage, goBack, insets} from "../../util";
import ArrowBackDark from '../../images/arrow-back-dark.png';
import ArrowBackLight from '../../images/arrow-back-light.png';

export default ({title, suffix, dark = false, border = false, absolute = false}) => {
    const location = useLocation();
    const paddingTop = insets.paddingTop === 0 ? 0 : insets.paddingTop - 20;

    const onBack = () => {
        if (location.pathname === '/')
            sendFlutterMessage(goBack);
        else
            window.history.back(-1);
    }

    return (
        <div
            className={`custom-navigate${border ? ' custom-border-navigate' : ''}${absolute ? ' custom-absolute' : ''}`}
            style={absolute ? {top: `${paddingTop}px`} : {marginTop: `${paddingTop}px`}}>
            <img className='back-img' onClick={onBack} src={dark ? ArrowBackDark : ArrowBackLight} alt=""/>
            <span className='title'>{title}</span>
            <div>{suffix}</div>
        </div>
    )
}