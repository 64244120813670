const {message} = require('antd');
const React = require('react');
const cookie = require("react-cookies");
const insets = {
    paddingTop: parseInt(cookie.load('padding-top')) || 0,
    paddingBottom: parseInt(cookie.load('padding-bottom')) || 0
};
const versionCode = parseInt(cookie.load('version-code') || 0);

const coinNavigation = "coin_navigation";
const customService = "custom_service";
const goBack = "back";

const sendFlutterMessage = (mes, data) => {
    if (!window.flutter_inappwebview) {
        sendHarmonyMessage(mes, data)
        return;
    }

    window.flutter_inappwebview?.callHandler(mes, data)
        .then((res) => {
            if (!res && versionCode < 105) {
                const p1 = React.createElement('p', {style: {margin: 0}}, '您的App版本过低，部分功能将无法使用');
                const p2 = React.createElement('p', {style: {margin: 0}}, '请升级至更高版本查看！');
                const div = React.createElement('div', null, p1, p2);
                message.info(div);
            }
        });
}

const sendHarmonyMessage = (mes, data) => {
    // eslint-disable-next-line no-undef
    console.log(harmonyCoinWebProxy)
    // eslint-disable-next-line no-undef
    if (!harmonyCoinWebProxy) {
        return;
    }
    switch (mes) {
        case coinNavigation:
            // eslint-disable-next-line no-undef
            harmonyCoinWebProxy.coinNavigation(data?.eventKey);
            break;
        case customService:
            // eslint-disable-next-line no-undef
            harmonyCoinWebProxy.customService();
            break;
        case goBack:
            // eslint-disable-next-line no-undef
            harmonyCoinWebProxy.back();
            break;
    }
}

const acupointEndpoint = (path) => `${cookie.load('host')}/${path}`;

const flutterUser = {
    authorization: cookie.load('Authorization'),
    channel: cookie.load('channel'),
    'version-code': cookie.load('version-code'),
    'wechat-group-key': cookie.load('wechat-group-key'),
    'app-info': cookie.load('app-info'),
};

const toMallPath = (path, kid, payload, data) => {
    const values = {kid, payload, ...data, theme: 'zhongji'};
    if (insets.paddingTop > 0) values.paddingTop = insets.paddingTop;
    if (insets.paddingBottom > 0) values.paddingBottom = insets.paddingBottom;
    let toPath = `${window.MALL_HOST}${path}?${new URLSearchParams(values).toString()}`;
    window.location.href = toPath;
}

module.exports = {
    coinNavigation,
    customService,
    sendFlutterMessage,
    flutterUser,
    goBack,
    acupointEndpoint,
    insets,
    toMallPath,
}
