import './index.css';
import CustomButton from "../../components/custom-button";
import {useEffect, useState} from "react";
import JsonRequest from "../../request/json_request";
import {coinNavigation, sendFlutterMessage, flutterUser, toMallPath} from "../../util";
import Navigate from "../../components/navigate";
import {Link, useNavigate} from "react-router-dom";
import Gold from '../../images/gold.png';
import TitlePrefix from '../../images/title-prefix.png';
import TitleSuffix from '../../images/title-suffix.png';
import ArrowRight from '../../images/arrow-right.png';
import Mission from '../../images/mission.png';
import Mall from '../../images/mall.png';
import SigninTitlePrefix from '../../images/signin-title-prefix.png';
import SigninTitleSuffix from '../../images/signin-title-suffix.png';
import SigninGold from '../../images/signin-gold.png';
import LoadingGif from '../../images/loading.gif';
import SigninTitle from '../../images/signin-title.png';
import {useSelector} from "react-redux";
import {selectSession} from "../../global";
import CustomLoading from "../../components/custom-loading";

export default () => {
    const [showSigninModal, setShowSIM] = useState(false);
    const [balance, setBalance] = useState(0);
    const [achieves, setAchieves] = useState([]);
    const client = JsonRequest.headerClient(flutterUser);
    const navigate = useNavigate();
    const session = useSelector(selectSession);

    const [malls, setMalls] = useState([]);

    useEffect(() => {
        flutterMessageHandle();
        getProducts();
    }, []);

    const getProducts = () => {
        client.get("/api/distributed/mall/products")
            .then(resp => resp.json())
            .then(({data}) => {
                setMalls(data?.payload.data.data || [])
            })
    }

    const flutterMessageHandle = (e) => {
        if (!!!flutterUser.authorization) return;
        getBalance();
        getAchievement();
    }

    const getBalance = () => {
        client.get("/api/coin/account")
            .then(resp => resp.json())
            .then(({data}) => {
                setBalance(data.available_balance);
            })
    }

    const getAchievement = (hasSign = false) => {
        client.get("/api/coin/achievement")
            .then(resp => resp.json())
            .then(({data}) => {
                setAchieves(data);
                if (!hasSign) checkSign(data);
            })
    }

    const checkSign = (achieves) => {
        const signEventKey = 'acupoint:coin:event::clock-in';
        const signAchieve = achieves.find(e => e.event_key === signEventKey);
        const {pool} = signAchieve;
        const finished = pool != null && pool.completed_at != null;
        // 自动签到
        if (!finished) signIn(signEventKey);
    }

    const signIn = (event_key) => {
        client.post(`/api/coin/${event_key}`)
            .then(resp => resp.json())
            .then(({data}) => {
                // signinRemind && setShowSIM(true);
                if (!data) return;
                setShowSIM(true);
                getBalance();
                getAchievement(true);
            })
    }

    const changeSigninModal = (show) => {
        setShowSIM(show);
        document.body.style.overflow = show ? 'hidden' : 'auto';
    }

    const toMyOrder = () => {
        const {kid, payload} = session;
        toMallPath('/orderList', kid, payload);
    }

    const MissionItem = ({data}) => {
        const {event_key, amount, name, summary, pool} = data;
        const finished = pool != null && pool.completed_at != null;
        const isSignIn = event_key === 'acupoint:coin:event::clock-in';
        return (
            <div className='mission-item'>
                <div>
                    <div className='mission-title'>
                        <span className='mission-name'>{name}</span>
                        <div className='mission-award'>
                            <span>+{amount}</span>
                            <img src={Gold} alt=""/>
                        </div>
                    </div>
                    <span className='mission-explain'>{summary}</span>
                </div>
                <CustomButton finished={finished} disable={isSignIn && finished}
                              onClick={() => !finished && !isSignIn && sendFlutterMessage(coinNavigation, {eventKey: event_key})}>
                    {finished ? isSignIn ? '明日签到' : '已完成' : '去完成'}
                </CustomButton>
            </div>
        )
    }

    const MallList = ({list}) => {
        const arr = [];
        for (let i = 0; i < list.length / 2; i++) {
            arr.push(
                <div className='mall-row' key={list[i * 2].id}>
                    <MallItem data={list[i * 2]}/>
                    {list.length > (i * 2 + 1) && <MallItem data={list[i * 2 + 1]}/>}
                </div>
            );
        }
        return (
            <div className='mall-list'>
                {arr}
            </div>
        )
    }

    const MallItem = ({data}) => {
        const {productSkuDTO, productName, isActivity} = data;
        const src = data.productSkuDTO?.images[0]?.objName;
        return (
            <div className='mall-item'
                 onClick={() => navigate('/goods', {state: {...data, balance}})}>
                <div className='mall-prize flex-center'>
                    <div><img src={src} alt=''/></div>
                </div>
                <span>{productName}</span>
                <div className='mall-convert'>
                    <div className='flex-center'>
                        <span className='mall-price'>{productSkuDTO?.productPrice}</span>
                        <img className='center-margin' src={Gold} alt="" width={21} height={19}/>
                        {isActivity && <span className='mall-price-activity'>(活动)</span>}
                    </div>
                    <div className='convert-button'>兑换</div>
                </div>
            </div>
        )
    }

    return (
        <div className='integral'>
            <Navigate absolute suffix={
                <div className='integal-tools'>
                    <span className='order-enter' onClick={toMyOrder}>我的订单</span>
                    <Link className='rule-enter' to='/rule'>规则</Link>
                </div>
            }/>
            <div className='my-integral'>
                <div className='my-integral-div' onClick={() => window.location.href = '/record'}>
                    <div className='my-integral-title flex-center'>
                        <img src={TitlePrefix} alt="" width={25} height={8}/>
                        <span>我的积分</span>
                        <img src={TitleSuffix} alt="" width={25} height={8}/>
                    </div>
                    <div className='my-integral-num flex-center'>
                        <span>{balance}</span>
                        <img src={Gold} alt="gold" className='center-margin' width={21} height={19}/>
                        <img src={ArrowRight} alt="gold" width={7} height={14}/>
                    </div>
                </div>
            </div>
            <div className='reels'>
                <div className='mission reel'>
                    <img className='reel-title' src={Mission} alt=""/>
                    {achieves.length === 0 ?
                        <div className='loading-box'><CustomLoading/></div> :
                        achieves.map(e => <MissionItem key={e.event_key} data={e}/>)}
                </div>
                <div className='mall reel'>
                    <img className='reel-title' src={Mall} alt=""/>
                    {malls.length === 0 ?
                        <div className='loading-box'><CustomLoading/></div> :
                        <MallList list={malls}/>
                    }
                    <div className='mall-footer'>—— 更多积分兑换好物陆续上线中 ——</div>
                </div>
            </div>
            <div className='signin-modal flex-center' style={{display: showSigninModal ? 'flex' : 'none'}}>
                <div className='signin-content flex-center'>
                    <div className='signin-body flex-center'>
                        <div className='signin-panel'>
                            <div className='signin-title flex-center'>
                                <img className='signin-title-fix' src={SigninTitlePrefix} alt=""/>
                                <img className='signin-title-img' src={SigninTitle} alt=""/>
                                <img className='signin-title-fix' src={SigninTitleSuffix} alt=""/>
                            </div>
                            <p className='signin-prosit'>恭喜你获得<span>2</span>积分</p>
                            <div className='sign-integral flex-center'>
                                <img src={SigninGold} alt=""/>
                            </div>
                            <p className='signin-seeu-tomorrow'>明日再来可继续获得积分</p>
                            <div className='signin-button' onClick={() => changeSigninModal(false)}>开心收下</div>
                        </div>
                    </div>
                    {/*<div className='hint-checker flex-center'>*/}
                    {/*    <span>签到提醒</span>*/}
                    {/*    <CustomChecker checked={signinRemind} onChange={(b) => setSigninRemind(b)}/>*/}
                    {/*</div>*/}
                </div>
            </div>
        </div>
    )
}