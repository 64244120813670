import './index.css';
import Navigate from "../../components/navigate";
import {useLocation} from "react-router-dom";
import {flutterUser, insets, toMallPath} from "../../util";
import Gold from '../../images/gold.png';
import Flow1 from '../../images/flow-1.png';
import Flow2 from '../../images/flow-2.png';
import Flow3 from '../../images/flow-3.png';
import FlowTo from '../../images/flow-to.png';
import {useSelector} from "react-redux";
import {selectSession} from "../../global";
import {useEffect, useState} from "react";
import JsonRequest from "../../request/json_request";

export default () => {
    const [balance, setBalance] = useState(null);
    const client = JsonRequest.headerClient(flutterUser);
    const session = useSelector(selectSession);
    const location = useLocation();
    const data = location.state;
    const {id, productName, summary, content} = data;
    const src = data.productSkuDTO?.images[0]?.objName;
    const price = data.productSkuDTO?.productPrice;

    useEffect(() => {
        getBalance();
    }, []);

    const tackOrder = () => {
        const {kid, payload} = session;
        toMallPath('/submitOrder', kid, payload, {productId: id});
    }

    const getBalance = () => {
        client.get("/api/coin/account")
            .then(resp => resp.json())
            .then(({data}) => {
                setBalance(data.available_balance);
            })
    }

    return (
        <div className='goods'>
            <Navigate dark absolute/>
            <div className='goods-body'>
                <div className='goods-avatar flex-center' style={{paddingTop: `${insets.paddingTop}px`}}>
                    <img src={src} alt=""/>
                    <div className='goods-avatar-gradient'></div>
                </div>
                <div className='goods-scroll'>
                    <div className='goods-info piece'>
                        <div className='goods-name'>{productName}</div>
                        <div className='goods-price'>
                            <span>{price}</span>
                            <img src={Gold} alt=""/>
                        </div>
                        <p className="goods-summary">{summary}</p>
                        <div className="goods-content" dangerouslySetInnerHTML={{__html: content}}/>
                    </div>
                    <div className='convert-flow piece'>
                        <div className='piece-title'>兑换流程</div>
                        <div className='convert-flow-content'>
                            <div className='flow-pics'>
                                <img className='flow-item' src={Flow1} alt=""/>
                                <span className='lineae'/>
                                <img className='flow-medi' src={FlowTo} alt=""/>
                                <span className='lineae'/>
                                <img className='flow-item' src={Flow2} alt=""/>
                                <span className='lineae'/>
                                <img className='flow-medi' src={FlowTo} alt=""/>
                                <span className='lineae'/>
                                <img className='flow-item' src={Flow3} alt=""/>
                            </div>
                            <div className='flow-name'>
                                <span>提交申请</span>
                                <span>客服处理</span>
                                <span>兑换完成</span>
                            </div>
                        </div>
                    </div>
                    <div className='need-to-know piece'
                         style={{marginBottom: `${insets.paddingBottom + 90}px`}}>
                        <div className='piece-title'>兑换须知</div>
                        <div className='need-to-know-text'>
                            1.兑换的物品分为虚拟物品和实物，兑换时会扣除相应的积分，兑换申请在客服处理完成后虚拟物品实时到账，实物将在7个工作日内进行邮寄。
                            <br/>
                            2.特殊物品兑换可能会需要您的支付宝账户，姓名、联系方式、邮寄地址等信息，工作人员将在审核后通过电话、APP内在线客服等方式联系您完善信息，请注意查收；你也可以通过我的-在线客服主动告知我们。
                            <br/>
                            3.兑换申请审核通过后，不可更改，无质量问题不可退换。
                            <br/>
                            4.每期物品数量有限，兑完即止，如有任何疑问请联系在线客服。
                        </div>
                    </div>
                </div>
            </div>
            <div className='goods-footer'>
                <button
                    disabled={balance < price}
                    style={{marginBottom: `${insets.paddingBottom}px`}}
                    className={balance < price ? 'disable-button' : ''}
                    onClick={() => balance >= price && tackOrder()}>
                    {balance === null ? '正在查询余额...' : balance >= price ? '兑换' : '积分不足'}
                </button>
            </div>
        </div>
    )
}